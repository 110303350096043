// Libs 
// Used to call fonts and other third party libraries
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

// General
// This is used to give us overall styling 
body {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
    height: 100vh;
    padding: 0;
    margin: 0;

    // Logo
    img.fl {
        display: block;
        margin-top: 15px;
    }
}